import i18n from '@/imports/startup/client/i18n';

export const ORDER_PROCESSES_HEADERS = {
  SUPPLIER: 'supplier',
  PRODUCT_COUNT: 'productCount',
  OPENED_AT: 'openedAt',
  SENT_AT: 'sentAt',
  CREATED_BEFORE: 'createdBefore',
  DELIVERY_ARRIVAL_TIME: 'deliveryArrivalTime',
  ORDER_CUT_OFF_TIME: 'orderCutOffTime',
  SENT_STATUS: 'sentStatus',
  UPDATED_AT: 'updatedAt',
  DISPATCHED_BY_USER: 'dispatchedByUser',
  OPENED_BY_USER: 'openedByUser',
  PRODUCT_COUNT_IN_PURCHASE_LIST: 'productCountInPurchaseList',
  PRODUCT_COUNT_WITH_MAX_STOCK_LEVEL: 'productCountWithMaxStockLevel',
  ORDER_ARRIVES_AT: 'orderArrivesAt',
};

export const getTranslation = (key) => i18n.t(`orders.table.headers.${key}`);

export const getClosedOrderProcessesColumns = () => [
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.SUPPLIER),
    key: ORDER_PROCESSES_HEADERS.SUPPLIER,
    width: '350px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.PRODUCT_COUNT),
    key: ORDER_PROCESSES_HEADERS.PRODUCT_COUNT,
    width: '115px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.ORDER_ARRIVES_AT),
    key: ORDER_PROCESSES_HEADERS.ORDER_ARRIVES_AT,
    width: '170px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.OPENED_BY_USER),
    key: ORDER_PROCESSES_HEADERS.OPENED_BY_USER,
    width: '76px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.OPENED_AT),
    key: ORDER_PROCESSES_HEADERS.OPENED_AT,
    width: '94px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.DISPATCHED_BY_USER),
    key: ORDER_PROCESSES_HEADERS.DISPATCHED_BY_USER,
    width: '76px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.SENT_AT),
    key: ORDER_PROCESSES_HEADERS.SENT_AT,
    width: '94px',
  },
];

export const getActiveOrderProcessesColumns = () => [
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.SUPPLIER),
    key: ORDER_PROCESSES_HEADERS.SUPPLIER,
    width: '228px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.PRODUCT_COUNT),
    key: ORDER_PROCESSES_HEADERS.PRODUCT_COUNT,
    width: '128px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.DELIVERY_ARRIVAL_TIME),
    key: ORDER_PROCESSES_HEADERS.DELIVERY_ARRIVAL_TIME,
    width: '158px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.ORDER_CUT_OFF_TIME),
    key: ORDER_PROCESSES_HEADERS.ORDER_CUT_OFF_TIME,
    width: '188px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.OPENED_BY_USER),
    key: ORDER_PROCESSES_HEADERS.OPENED_BY_USER,
    width: '80px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.CREATED_BEFORE),
    key: ORDER_PROCESSES_HEADERS.OPENED_AT,
    width: '112px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.SENT_STATUS),
    key: ORDER_PROCESSES_HEADERS.SENT_STATUS,
    width: '112px',
  },
  {
    header: '',
    width: '60px',
    key: 'kebab',
    customClass: 'p-0',
  },
];

export const getRestockProductsListsColumns = () => [
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.SUPPLIER),
    key: ORDER_PROCESSES_HEADERS.SUPPLIER,
    width: '205px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.PRODUCT_COUNT_WITH_MAX_STOCK_LEVEL),
    key: ORDER_PROCESSES_HEADERS.PRODUCT_COUNT_WITH_MAX_STOCK_LEVEL,
    width: '80px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.PRODUCT_COUNT_IN_PURCHASE_LIST),
    key: ORDER_PROCESSES_HEADERS.PRODUCT_COUNT_IN_PURCHASE_LIST,
    width: '100px',
  },
  {
    header: getTranslation(ORDER_PROCESSES_HEADERS.UPDATED_AT),
    key: ORDER_PROCESSES_HEADERS.UPDATED_AT,
    width: '150px',
  },
];

export const getRestockProductsListsModalColumns = () => [
  { header: i18n.t('orders.restockProductsLists.table.headers.name'), key: 'name', width: '400px' },
  { header: i18n.t('orders.restockProductsLists.table.headers.sku'), key: 'sku' },
  { header: i18n.t('orders.restockProductsLists.table.headers.maxStockLevel'), key: 'maxStockLevel' },
];
