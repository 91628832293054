<template>
  <div>
    <el-tooltip
      v-if="cutOffDate"
      placement="top"
      popper-class="cut-off"
      :content="
        $t('orders.table.headers.orderCutOffTimeTooltip', {
          cutOff: formatDayAndTime(cutOffDate),
          arrivalTime: formatDayAndTimeWithDate(arrivalTime),
        })
      "
    >
      <div class="order-cutoff">
        <Countdown :only-time="true" :date="cutOffDate" @on-duration-change="onDurationChange" />
      </div>
    </el-tooltip>
    <p v-else>{{ MISSING_DATA_TEXT }}</p>
  </div>
</template>
<script>
import { ref, computed } from 'vue';

import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import {
  formatDayAndTime,
  formatDayAndTimeWithDate,
  returnDashInsteadOfNull,
} from '../../../purchase-management/purchaseManagementFormatters';
import {
  getClosestOrderTermCutOffDate,
  getClosestDeliveryArrivalDate,
} from '../../../purchase-management/purchaseManagement';

import Countdown from '../Countdown.vue';

export default {
  name: 'OrderCutOffTimeCell',
  components: { Countdown },
  props: {
    supplierId: { type: String, required: true },
    allTerms: { type: Array, required: true },
  },
  emits: ['on-order-cut-off-change'],
  setup(props, { emit }) {
    const cutOffDate = ref(null);
    const arrivalTime = ref(null);

    const cutOffDateReactive = computed(() => getClosestOrderTermCutOffDate(props.supplierId, props.allTerms));
    const arrivalTimeReactive = computed(() => getClosestDeliveryArrivalDate(props.supplierId, props.allTerms));

    const onDurationChange = () => {
      cutOffDate.value = getClosestOrderTermCutOffDate(props.supplierId, props.allTerms);
      arrivalTime.value = getClosestDeliveryArrivalDate(props.supplierId, props.allTerms);
      emit('on-order-cut-off-change');
    };

    const actualCutOffDate = computed(() => cutOffDate.value ?? cutOffDateReactive.value);
    const actualArrivalTime = computed(() => arrivalTime.value ?? arrivalTimeReactive.value);

    return {
      MISSING_DATA_TEXT,
      cutOffDate: actualCutOffDate,
      arrivalTime: actualArrivalTime,
      onDurationChange,
      formatDayAndTime: (date) => returnDashInsteadOfNull(formatDayAndTime(date)),
      formatDayAndTimeWithDate: (date) => returnDashInsteadOfNull(formatDayAndTimeWithDate(date)),
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.order-cutoff {
  width: fit-content;
}
</style>
<style lang="scss">
.cut-off {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
