<template>
  <el-dialog
    class="restock-products-list-modal"
    :visible="true"
    custom-class="rounded restock-products-list-modal"
    :close-on-click-modal="false"
    :show-close="false"
    @close="onCancel"
  >
    <div slot="title" class="d-flex w-100 justify-content-between">
      <div class="d-flex flex-column gap-1 mb-4">
        <h2>{{ $t('orders.restockProductsLists.modal.title') }}</h2>
        <p>{{ supplierName }}</p>
      </div>
      <Button type="icon" class="p-0" @click="onCancel">
        <CloseIcon />
      </Button>
    </div>
    <div class="info">
      <InformationFullIcon :size="16" />
      <p>{{ $t('orders.restockProductsLists.description') }}</p>
    </div>
    <div class="table-wrapper">
      <table v-if="showTable" class="table">
        <thead>
          <tr>
            <th scope="col" class="header-drag">
              <p></p>
            </th>
            <th scope="col" class="header-index">
              <p>#</p>
            </th>
            <th
              v-for="(column, columnIndex) in columns"
              :key="`${columnIndex}-${column.key}`"
              :width="column.width"
              scope="col"
            >
              <div class="d-flex gap-1 justify-content-between">
                <div class="d-flex gap-1">
                  <div class="header-text">
                    <p class="fw-bold d-inline">{{ column.header }}</p>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody ref="tableBody">
          <tr v-if="data.length === 0">
            <td :colspan="colspan" class="text-center">
              {{ $t('orders.restockProductsLists.noData') }}
            </td>
          </tr>

          <template v-for="(rowData, rowIndex) in data" v-else>
            <tr
              :key="rowData.id || rowIndex"
              @mouseover="rowIndexStockLevelEdit = rowIndex"
              @mouseout="rowIndexStockLevelEdit = -1"
            >
              <td class="handle">
                <div class="hamburger" :class="{ grabbing: grabbingCursor, grab: !grabbingCursor }">
                  <DragHandleIcon :size="16" />
                </div>
              </td>

              <td>
                {{ rowIndex + 1 }}
              </td>

              <td>
                <div class="d-flex gap-2 align-items-center">
                  {{ rowData.name }}
                  <ListCheckIcon class="check" :size="16" />
                </div>
              </td>

              <td>
                <TruncatedText>
                  {{ rowData.sku ?? MISSING_DATA_TEXT }}
                </TruncatedText>
              </td>

              <td>
                <div v-if="rowIndexStockLevelEdit === rowIndex || rowData.focus === true" class="max-stock-level-input">
                  <input
                    type="number"
                    min="1"
                    :value="rowData.maxStockLevel"
                    @focusin="() => onRowFocus(rowData, true)"
                    @focusout="() => onRowFocus(rowData, false)"
                    @input="(newValue) => updateStockLevel(rowIndex, newValue)"
                  />
                </div>
                <div v-else class="max-stock-level">
                  <p v-if="rowData.maxStockLevel">
                    {{ rowData.maxStockLevel }}
                  </p>
                  <p v-else class="missing-text">
                    {{ MISSING_DATA_TEXT }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <span slot="footer">
      <Button type="secondary" @click="onCancel">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" :loading="submitLoading" @click="onSubmit">{{ $t('commons.save') }}</Button>
    </span>
  </el-dialog>
</template>
<script>
import { ref, watch, computed } from 'vue';
import Sortable from 'sortablejs';
import { clone } from 'ramda';

import { Button, TruncatedText } from '@/modules/core';
import { DragHandleIcon, ListCheckIcon, InformationFullIcon, CloseIcon } from '@/assets/icons';

import { getRestockProductsListsModalColumns } from '../orderProcessesColumns';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

function switchItems(list, oldIndex, newIndex) {
  const clonedList = clone(list);
  const item = clonedList[oldIndex];
  clonedList.splice(oldIndex, 1);
  const firstPart = clonedList.slice(0, newIndex);
  const secondPart = clonedList.slice(newIndex);

  return firstPart.concat(item, secondPart);
}

export default {
  name: 'RestockProductsListModal',
  components: { Button, TruncatedText, DragHandleIcon, ListCheckIcon, InformationFullIcon, CloseIcon },
  props: {
    productList: { type: Array, default: () => null },
    supplierName: { type: String, default: () => null },
    restockProductsListId: { type: String, default: () => null },
    submitLoading: { type: Boolean, required: true },
  },
  emits: ['on-cancel', 'on-submit'],
  setup(props, { emit }) {
    const tableBody = ref(null);
    const updatedData = ref(null);
    const showTable = ref(true);
    const rowIndexStockLevelEdit = ref(-1);
    const grabbingCursor = ref(false);

    const onCancel = () => {
      data.value = null;
      resetProductsFocus();
      toggleTable();
      emit('on-cancel');
    };

    const data = computed({
      get: () => {
        const relevant = updatedData.value || clone(props.productList);
        return relevant;
      },
      set: (newValue) => (updatedData.value = newValue),
    });

    const onRowDragged = ({ newIndex, oldIndex }) => {
      data.value = switchItems(data.value, oldIndex, newIndex);
      grabbingCursor.value = false;
    };

    const onRowDragStart = () => {
      grabbingCursor.value = true;
    };

    const onSubmit = () => {
      emit('on-submit', {
        id: props.restockProductsListId,
        products: data.value,
      });
    };

    watch(tableBody, () => {
      if (tableBody.value) {
        Sortable.create(tableBody.value, {
          handle: '.handle',
          ghostClass: 'ghost',
          animation: 300,
          onStart: onRowDragStart,
          onEnd: onRowDragged,
        });
      }
    });

    const updateStockLevel = (rowIndex, event) => {
      const currentValue = data.value[rowIndex].maxStockLevel;
      const newValue = Number(event.target.value);
      if (currentValue !== newValue) {
        data.value[rowIndex] = { ...data.value[rowIndex], maxStockLevel: newValue === 0 ? 1 : newValue };
      }
    };

    const toggleTable = () => {
      showTable.value = false;
      showTable.value = true;
    };
    const resetProductsFocus = () => data.value.forEach((rowData) => (rowData.focus = false));

    const onRowFocus = (rowData, focus) => {
      resetProductsFocus();
      rowData.focus = focus;
      toggleTable();
    };

    return {
      MISSING_DATA_TEXT,
      data,
      tableBody,
      showTable,
      grabbingCursor,
      rowIndexStockLevelEdit,
      columns: getRestockProductsListsModalColumns(),
      onSubmit,
      onCancel,
      onRowFocus,
      onRowDragged,
      updateStockLevel,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$header-height: 79px; // Element header height
$footer-height: 62px; // Element footer height
::v-deep {
  .restock-products-list-modal {
    height: 90vh;
    width: 1216px;
    margin-top: 3rem !important;
    margin-bottom: 0rem !important;
  }
  .el-dialog__header {
    height: $header-height;
    border-radius: inherit;
    background: $white;
    margin-bottom: 1rem;
  }
  .el-dialog__body {
    height: calc(100% - calc($header-height + $footer-height));
    padding-top: 0;
    padding-bottom: 0;
  }
  .el-dialog__footer {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.description {
  color: $typography-secondary;
  word-break: break-word;
  padding-inline-start: 4px;
}

table > thead,
table > tbody {
  word-break: normal;
}

table > thead > tr > th,
table > tbody > tr > td {
  background: $white;
}

tr:last-child > td {
  border-bottom-width: 0px;
}

table {
  border: 1px solid #e5e8ea;
  border-radius: 1rem;
}

tr {
  &:hover {
    td {
      background: $light-gray;
    }
  }
}

.hamburger {
  color: $typography-secondary;
  &:active {
    cursor: grabbing;
  }
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.ghost {
  td {
    opacity: 0.5 !important;
    background-color: $light-gray;
  }
}
tr {
  .stock-level {
    visibility: hidden;
  }
  &:hover {
    .stock-level {
      visibility: visible;
    }
  }
}
.table-wrapper {
  overflow: auto;
  height: calc(100% - calc($footer-height + 2rem));
}
.header-drag {
  width: 55px;
}
.header-index {
  width: 35px;
}
.check {
  color: #11874f;
}
.max-stock-level {
  margin-inline-start: 3px;
}
.missing-text {
  color: $typography-secondary;
}
.max-stock-level-input {
  input {
    height: 1.3rem;
    width: 3rem;
    text-align: right;

    border-radius: 0.25rem;
    border: 1px solid var(--border-color-border-2, #e3e4e6);
    background: #fff;

    &:hover {
      border: 1px solid #71767d;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }
  }
}
.info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 4px;
  border: 1px solid #e9e9eb;
  background-color: #f4f4f5;
  color: #909399;
  margin-bottom: 1rem;
}
</style>
