<template>
  <div
    class="bg-primary rounded-pill d-flex justify-content-center align-items-center"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <span class="letters">{{ avatarLetters }}</span>
  </div>
</template>

<script>
export default {
  name: 'UserInitialAvatar',
  props: {
    user: { type: Object, required: true },
    size: { type: Number, required: false, default: 24 },
  },
  computed: {
    avatarLetters() {
      return `${this.user.firstName[0]}${this.user.lastName[0]}`;
    },
  },
};
</script>

<style scoped lang="scss">
.letters {
  font-size: 0.65rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff !important;
}
</style>
