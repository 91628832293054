import i18n from '@/imports/startup/client/i18n';

export const getTranslation = (key) => i18n.t(`orders.table.headers.${key}`);

export const TABLE_HEADERS = {
  LATEST_ORDER: 'latestOrder',
  NAME: 'name',
  ACTIONS: 'actions',
  DELIVERY_ARRIVAL_TIME: 'deliveryArrivalTime',
  ORDER_CUT_OFF_TIME: 'orderCutOffTime',
};

export const tableColumns = () => [
  { header: getTranslation('supplier'), key: TABLE_HEADERS.NAME },
  { header: getTranslation('lastOrder'), key: TABLE_HEADERS.LATEST_ORDER, width: '250px' },
  {
    header: getTranslation(TABLE_HEADERS.DELIVERY_ARRIVAL_TIME),
    key: TABLE_HEADERS.DELIVERY_ARRIVAL_TIME,
    width: '180px',
  },
  {
    header: getTranslation(TABLE_HEADERS.ORDER_CUT_OFF_TIME),
    key: TABLE_HEADERS.ORDER_CUT_OFF_TIME,
    width: '200px',
  },
  { header: '', key: TABLE_HEADERS.ACTIONS, width: '12%' },
];
