<template>
  <div v-if="openedAt">
    <el-tooltip placement="top" effect="dark" popper-class="user-avatar">
      <div slot="content" class="d-flex flex-column">
        <div>{{ `${$t('orders.table.headers.openedByUser')} ${fullName}` }}</div>
        <div>{{ formatDateAndTime(openedAt) }}</div>
      </div>
      <img v-if="user.profilePicture" :src="profilePictureUri" alt="" :width="size" :height="size" />
      <UserInitialAvatar v-else :user="user" :size="size" />
    </el-tooltip>
  </div>
  <div v-else-if="user.profilePicture">
    <el-tooltip placement="top" effect="dark" popper-class="user-avatar" :content="fullName">
      <img :src="profilePictureUri" alt="" :width="size" :height="size" />
    </el-tooltip>
  </div>
  <div v-else>
    <el-tooltip placement="top" effect="dark" popper-class="user-avatar" :content="fullName">
      <UserInitialAvatar :user="user" :size="size" />
    </el-tooltip>
  </div>
</template>
<script>
import { computed } from 'vue';

import UserInitialAvatar from './UserInitialAvatar.vue';

import { formatDateAndTime } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  name: 'UserAvatar',
  components: {
    UserInitialAvatar,
  },
  props: {
    user: { type: Object, required: true },
    openedAt: { type: Number, required: false, default: undefined },
    token: { type: String, required: true },
    size: { type: Number, required: false, default: 24 },
  },
  setup(props) {
    const fullName = computed(() => `${props.user.firstName} ${props.user.lastName}`);

    const profilePictureUri = computed(() => `${props?.user?.profilePicture}&token=${props.token}`);

    return {
      formatDateAndTime,
      fullName,
      profilePictureUri,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.user-avatar {
  &.el-tooltip__popper {
    padding: 5px;
  }
}
</style>
