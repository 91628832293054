<template>
  <TruncatedText v-if="arrivesAt" class="w-100">
    {{ formatDayDateAndTime(arrivesAt) }}
  </TruncatedText>
  <div v-else>
    <el-tooltip
      v-if="deliveryArrivalDate"
      placement="top"
      :content="formatDate(deliveryArrivalDate)"
      popper-class="delivery-arrival-tooltip"
    >
      <TruncatedText class="w-100">{{ formatDayDateAndTime(deliveryArrivalDate) }}</TruncatedText>
    </el-tooltip>
    <p v-else>{{ MISSING_DATA_TEXT }}</p>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { TruncatedText } from '@/modules/core/components';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import {
  formatDate,
  formatDayAndTime,
  formatDayDateAndTime,
  returnDashInsteadOfNull,
} from '../../../purchase-management/purchaseManagementFormatters';

import { getClosestDeliveryArrivalDate, isWithinAWeekFromNow } from '../../../purchase-management/purchaseManagement';

export default {
  name: 'DeliveryArrivalTimeCell',
  components: { TruncatedText },
  props: {
    supplierId: { type: String, required: true },
    allTerms: { type: Array, required: true },
    arrivesAt: { type: Number, default: null },
  },
  emits: ['on-delivery-arrival-time-change'],
  setup(props, { emit }) {
    const deliveryArrivalDate = ref(null);

    const deliveryArrivalDateReactive = computed(() => getClosestDeliveryArrivalDate(props.supplierId, props.allTerms));

    const updateDeliveryArrivalDate = () => {
      const closestDate = getClosestDeliveryArrivalDate(props.supplierId, props.allTerms);
      if (deliveryArrivalDate.value && deliveryArrivalDate.value.getTime() !== closestDate.getTime()) {
        deliveryArrivalDate.value = closestDate;
        emit('on-delivery-arrival-time-change');
      }
    };

    let updateDeliveryArrivalDateIntervalId = null;
    onMounted(() => {
      updateDeliveryArrivalDate();
      updateDeliveryArrivalDateIntervalId = setInterval(updateDeliveryArrivalDate, 1000);
    });

    onBeforeUnmount(() => clearInterval(updateDeliveryArrivalDateIntervalId));
    const actualDeliveryArrivalDate = computed(() => deliveryArrivalDate.value ?? deliveryArrivalDateReactive.value);

    return {
      MISSING_DATA_TEXT,
      deliveryArrivalDate: actualDeliveryArrivalDate,
      isWithinAWeekFromNow,
      formatDate: (date) => returnDashInsteadOfNull(formatDate(date)),
      formatDayAndTime: (date) => returnDashInsteadOfNull(formatDayAndTime(date)),
      formatDayDateAndTime: (date) => returnDashInsteadOfNull(formatDayDateAndTime(date)),
    };
  },
};
</script>

<style lang="scss">
.delivery-arrival-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
</style>
