<template>
  <PageLayout>
    <template #header>
      <div>
        <h1>{{ $t('routes.orderSettings') }}</h1>
      </div>
    </template>
    <RestockProductsListTable
      :page="page"
      :loading="loading"
      :limit="TABLE_LIMIT"
      :total="totalRestockProductsLists"
      :current-tenant-id="currentTenantId"
      :restock-products-lists="restockProductsLists"
      :suppliers-purchase-list-products="suppliersPurchaseListProducts"
      @on-page-changed="onPageChanged"
      @on-refetch="restockProductsListsRefetch"
    />
  </PageLayout>
</template>

<script>
import { ref, computed } from 'vue';

import { PageLayout } from '@/modules/core/components';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { useTenancy } from '@/modules/auth';

import RestockProductsListTable from './RestockProductsListTable.vue';
import { usePurchaseListItems } from '../../compositions/usePurchaseListItems';
import { useRestockProductsLists } from '../../compositions/useRestockProductsLists';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

const PURCHASE_LIST_LIMIT = 100;
const TABLE_LIMIT = 25;
export default {
  components: {
    PageLayout,
    RestockProductsListTable,
  },
  setup() {
    const page = ref(1);
    const after = computed(() => TABLE_LIMIT * (page.value - 1));
    const { currentTenant, currentTenantId } = useTenancy();

    const {
      restockProductsLists: restockProductsListsPaginatedResponse,
      loading: restockProductsListsLoading,
      refetch: restockProductsListsRefetch,
    } = useRestockProductsLists({
      currentTenant,
      first: TABLE_LIMIT,
      after,
    });

    const { allResults: purchaseListItems, loading: purchaseListItemsLoading } = useFetchAll({
      hook: usePurchaseListItems,
      variables: {
        currentTenant,
        isRecommended: true,
      },
      dataSelector: 'purchaseListItems',
      limit: PURCHASE_LIST_LIMIT,
    });

    const getMaxStockLevelProductCount = (restockProductsList) =>
      restockProductsList.products.filter(({ maxStockLevel }) => maxStockLevel && maxStockLevel > 0).length;

    const loading = computed(() => restockProductsListsLoading.value || purchaseListItemsLoading.value);
    const suppliersPurchaseListProducts = computed(() => {
      if (!purchaseListItemsLoading.value) {
        const supplierMap = {};
        purchaseListItems.value.forEach((purchaseListItem) => {
          supplierMap[purchaseListItem.supplier.id] = supplierMap[purchaseListItem.supplier.id] || [];
          supplierMap[purchaseListItem.supplier.id].push({
            product: purchaseListItem.product,
            supplier: purchaseListItem.supplier,
          });
        });

        return supplierMap;
      }
      return {};
    });

    const totalRestockProductsLists = computed(() => restockProductsListsPaginatedResponse.value.totalCount);
    const restockProductsLists = computed(() => {
      const restockProductsListsToReturn = restockProductsListsPaginatedResponse.value.nodes.map((restockList) => ({
        ...restockList,
        productCountInPurchaseList: suppliersPurchaseListProducts.value[restockList.supplier.id]
          ? suppliersPurchaseListProducts.value[restockList.supplier.id].length
          : MISSING_DATA_TEXT,
        productCountWithMaxStockLevel: suppliersPurchaseListProducts.value[restockList.supplier.id]
          ? getMaxStockLevelProductCount(restockList)
          : MISSING_DATA_TEXT,
      }));

      Object.keys(suppliersPurchaseListProducts.value).forEach((supplierId) => {
        const existingRestockProductsLists = restockProductsListsToReturn.find(
          ({ supplier }) => supplier.id === supplierId
        );
        if (!existingRestockProductsLists) {
          const supplier = suppliersPurchaseListProducts.value[supplierId][0].supplier; // All of the items have the same data on the supplier (same supplier)
          restockProductsListsToReturn.push({
            supplier,
            productCountInPurchaseList: suppliersPurchaseListProducts.value[supplierId].length,
            supplierId: supplier.id,
          });
        }
      });

      return restockProductsListsToReturn;
    });

    const onPageChanged = ({ page: newPage }) => {
      page.value = newPage;
    };

    return {
      TABLE_LIMIT,
      page,
      loading,
      currentTenantId,
      purchaseListItems,
      restockProductsLists,
      totalRestockProductsLists,
      suppliersPurchaseListProducts,
      onPageChanged,
      restockProductsListsRefetch,
    };
  },
};
</script>
